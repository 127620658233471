import { render, staticRenderFns } from "./ChangeUserBalance.vue?vue&type=template&id=bc74a4be&"
import script from "./ChangeUserBalance.vue?vue&type=script&lang=js&"
export * from "./ChangeUserBalance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/user0/cvizor/cvizor-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bc74a4be')) {
      api.createRecord('bc74a4be', component.options)
    } else {
      api.reload('bc74a4be', component.options)
    }
    module.hot.accept("./ChangeUserBalance.vue?vue&type=template&id=bc74a4be&", function () {
      api.rerender('bc74a4be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lk/ChangeUserBalance.vue"
export default component.exports