<template>
  <CCard
    color="second-card-bg mb-0"
    class="ccard shadow-none"
  >
    <CCardHeader class="ccard-header">
      <span>{{ $t('lk.permission.users') }}</span>
      <CSpinner
        class="ml-3"
        v-if="loadingTable"
        color="primary"
        size="sm"
      />
      <CLoadingButtonCustom
        class="ml-auto studetTable__header-btn"
        color="sub-btn"
        @click.native="exportToExcel()"
        >{{$t('lk.studentTable.export')}}</CLoadingButtonCustom
      >
    </CCardHeader>
    <CCardBody class="ccard-body d-flex flex-column">
      <CInput
        v-model="search"
        :placeholder="$t('general.inputPlaceholder')"
      >
        <template #prepend>
          <CButton class="shadow-none" color="primary">
            <CIcon name="cil-search"/>
            {{ $t('general.search') }}
          </CButton>
        </template>
      </CInput>

      <div class="grid-wrap">
        <div class="grid">
          <div
            v-for="(cell, i) in fields"
            :key="i"
            class="grid__cell grid__cell_header"
          >
            <div class="inner">
              {{ cell.label }}
            </div>
          </div>

          <template
            v-for="(item, i) in userList"
            class="grid__row"
          >
            <div :key="'name' + i" class="grid__cell">
              <div class="inner">
                {{ item.name }}
              </div>
            </div>

            <div :key="'email' + i" class="grid__cell" @click="$emit('openAccessModal', item)">
              <div class="inner _cursor">
                {{ item.email }}
              </div>
            </div>

            <div :key="'tariff' + i" class="grid__cell">
              <div class="inner">
                {{ item.tariff.name[locale] }}
              </div>
            </div>

            <div :key="'days-left' + i" class="grid__cell">
              <CInput
                :value="item.newTariffDaysLeft"
                class="mb-0"
                readonly
              />
            </div>

            <div :key="'expired' + i" class="grid__cell">
              <v-date-picker
                mode="date"
                :value="item.newTariffExpired"
                :is-dark="$store.getters.darkMode"
                :masks="{
                  input: 'DD.MM.YYYY',
                }"
                :min-date="new Date()"
                :locale="$root.$i18n.locale"
                :popover="{
                  positionFixed: true
                }"
                @input="setTariffExpired(item, $event)"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <CInput
                    :value="inputValue"
                    :placeholder="$t('general.datePlaceholder')"
                    class="input mb-0"
                    readonly
                    v-on="inputEvents"
                  >
                    <template #prepend-content>
                      <CIcon
                        v-if="item.newTariffExpired !== item.tariffExpired"
                        name="cil-x"
                        class="text-danger reset-date"
                        @click.native="setTariffExpired(item, null)"
                      />
                      <CIcon
                        v-else
                        name="cil-calendar"
                      />
                    </template>
                  </CInput>
                </template>
              </v-date-picker>
            </div>

            <div :key="`tt_enable_trends${i}`" class="grid__cell">
              <CInputCheckbox :checked.sync="item.enableTrends">
                <template v-slot:label>
                  <CIcon
                    v-if="item.enableTrends"
                    class="icon-lock text-danger"
                    name="eye-open"
                    @click="item.enableTrends = !item.enableTrends"
                  />
                  <CIcon
                    v-if="!item.enableTrends"
                    class="icon-lock text-danger"
                    name="eye-close"
                    @click="item.enableTrends = !item.enableTrends"
                  />
                </template>
              </CInputCheckbox>
            </div>

            <div :key="'lastLoginAt' + i" class="grid__cell">
              <div class="inner">
                {{ item.lastLoginAt }}
              </div>
            </div>

            <div :key="'createdAt' + i" class="grid__cell">
              <div class="inner">
                {{ item.createdAt }}
              </div>
            </div>

            <div :key="'balance' + i" class="grid__cell" @click="$emit('openBalanceModal', item)">
              <div class="inner _cursor">
                <span class="font-weight-bold">{{ item.balance }}</span> USDT
              </div>
            </div>

            <div :key="'refBalance' + i" class="grid__cell">
              <div class="inner">
                <span class="font-weight-bold">{{ item.refBalance }}</span> USDT
              </div>
            </div>

            <div :key="'block' + i" class="grid__cell">
              <CInputCheckbox :checked.sync="item.newBlocked">
                <template v-slot:label>
                  <CIcon
                    v-if="item.newBlocked"
                    class="icon-lock text-danger"
                    name="cil-lock-locked"
                    @click="item.newBlocked = !item.newBlocked"
                  />
                  <CIcon
                    v-else
                    class="icon-lock"
                    name="cil-lock-unlocked"
                    @click="item.newBlocked = !item.newBlocked"
                  />
                </template>
              </CInputCheckbox>
            </div>

            <div :key="'resetTelegram' + i" class="grid__cell">
              <CButton
                :disabled="!!item.tgLink || item.permissionTableLoad"
                :title="item.tgLink ? $t('lk.permission.telegramAttached') : ''"
                class="shadow-none w-100"
                color="warning"
                @click.stop="resetTelegramClick(item)"
              >
                <CSpinner
                  v-if="item.permissionTableLoad"
                  color="primary"
                  size="sm"
                />
                <div v-else>{{ $t('general.reset') }}</div>
              </CButton>
            </div>

            <div :key="'referrer' + i" class="grid__cell">
              <div class="inner">
                <span class="font-weight-bold">{{ item.referrer }}</span>
              </div>
            </div>

            <div :key="'referrer_link' + i" class="grid__cell">
              <div class="inner">
                <a target="_blank" :href="getFullRefUrl(item.referralCode)">{{ item.referralCode }}</a>
              </div>
            </div>

            <div :key="'google2fa' + i" class="grid__cell">
              <CLoadingButtonCustom
                v-if="item.google2faEnabled"
                color="warning"
                class="shadow-none w-100"
                @click.native="disable2FA(item)"
                :loading="loadingRequest === item.id"
              >
                Отключить
              </CLoadingButtonCustom>
              <span v-else>
                Отключена
              </span>
            </div>

            <div :key="'save' + i" class="grid__cell">
              <CButton
                :disabled="!item.hasPermissionTableChange || item.permissionTableLoad"
                class="shadow-none"
                color="success"
                @click.stop="updateUser(item)"
              >
                <CSpinner
                  v-if="item.permissionTableLoad"
                  color="primary"
                  size="sm"
                />
                <CIcon
                  v-else
                  name="cil-save"
                />
              </CButton>
            </div>
          </template>
        </div>

      </div>
      <CPagination
        class="mt-2 pagination-custom"
        :class="{_disabled: loadingTable}"
        v-show="totalPages > 1"
        :activePage.sync="page"
        :pages="totalPages"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import {DatePicker} from 'v-calendar'
import moment from "moment";
import User from "@/assets/js/User.class";
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  name: "PermissionTable",
  components: {
    'v-date-picker': DatePicker
  },
  data() {
    return {
      search: null,
      loadingTable: false,
      userList: [],
      page: 1,
      totalPages: null,
      loadingRequest: 0,
      fields: [
        {key: 'name', label: this.$t('lk.permission.table.name')},
        {key: 'email', label: this.$t('lk.permission.table.email')},
        {key: 'tariff', label: this.$t('lk.permission.table.tariff')},
        {key: 'tariffDaysLeft', label: this.$t('lk.permission.table.tariffDaysLeft')},
        {key: 'tariffExpired', label: this.$t('lk.permission.table.tariffExpired')},
        {key: 'enableTrends', label: this.$t('lk.permission.table.trends')},
        {key: 'lastLoginAt', label: this.$t('lk.permission.table.lastLoginAt')},
        {key: 'createdAt', label: this.$t('lk.permission.table.createdAt')},
        {key: 'balance', label: this.$t('lk.permission.table.balance')},
        {key: 'refBalance', label: this.$t('lk.permission.table.refBalance')},
        {key: 'active', label: this.$t('lk.permission.table.active')},
        {key: 'tgLink', label: this.$t('lk.permission.table.tgLink')},
        {key: 'referrer', label: this.$t('lk.permission.table.referrer')},
        {key: 'referrer_link', label: this.$t('lk.permission.table.referrer_link')},
        {key: 'google_2fa', label: '2FA'},
        {key: 'save', label: ''}
      ]
    }
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale
    }
  },
  created() {
    this.getUsers()
  },
  watch: {
    search(val) {
      if (typeof val !== 'string') return

      if (val.length >= 3) {
        this.page = 1
        const params = {}
        if (val) params.term = val

        debounce(this.getUsers, 300)(params)
      } else if (val.length === 0) {
        debounce(this.getUsers, 300)()
      }
    },
    page(val, pldVal) {
      if (val && val !== pldVal) {
        this.getUsers()
      }
    }
  },
  methods: {
    getFullRefUrl(refCode) {
      return document.location.origin + this.$router.resolve({
        name: 'referral-link',
        params: {
          code: refCode
        }
      }).href;
    },
    disable2FA(user) {
      this.loadingRequest = user.id
      axios.post('/api/v2/2fa/disable', {
        user_id: user.id
      })
        .then(({data}) => {
          if (data?.status && !data?.google2fa) {
            this.$toastr.success('2FA аутентификация отключена')
            this.userList = []
            this.getUsers()
          }
        })
        .catch((er) => {
          console.error(er)
        })
        .finally(() => {
          this.loadingRequest = 0
        })
    },
    getUsers(params) {
      this.loadingTable = true

      const paramsLocal = {
        ...params,
        page: this.page,
      }

      if (this.search) {
        paramsLocal.term = this.search
      }
      User.getAll(paramsLocal)
        .then((res) => {
          const {data, status} = res
          if (status < 300) {
            this.userList = data.data
            this.page = data.meta.current_page
            this.totalPages = data.meta.last_page
          }
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    updateUser(user) {
      user.permissionTableLoad = true
      user.update()
        .then(({status}) => {
          if (status < 300) {
            this.getUsers()
          }
        })
        .finally(() => {
          user.permissionTableLoad = false
        })
    },
    resetTelegramClick(user) {
      user.permissionTableLoad = true
      user.resetTelegram()
        .then(({status}) => {
          if (status < 300) {
            this.getUsers()
          }
        })
        .finally(() => {
          user.permissionTableLoad = false
        })
    },
    setTariffExpired(user, payload) {
      if (payload) {
        user.newTariffExpired = payload

        // добавляется один день для визуально правильного отображения (на сервере автоматически до 23:59:59)
        const expired = new moment(user.newTariffExpired).add(1, 'day')
        if (expired.isValid()) {
          const now = Date.now()
          user.newTariffDaysLeft = expired.diff(now, 'days')
        }
      } else {
        user.newTariffExpired = user.tariffExpired
        user.newTariffDaysLeft = user.tariffDaysLeft
      }
    },
    exportToExcel() {
      if (!this.isLoading) {
        this.isLoading = true
        axios
          .get("/api/v2/usersExport", { responseType: 'blob' })
          .then((data) => {
            if(data.data){
              let blobUrl = URL.createObjectURL(data.data)
              let linkTag = document.createElement("a")
              linkTag.href = blobUrl
              linkTag.download = `export_user_${new Date().toLocaleDateString('de-DE') + ' ' + new Date().toLocaleTimeString('it-IT')}.xls`
              linkTag.click()
            }
          })
          .finally(() => {
            this.isLoading = false
          });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
    .grid__cell .inner {
      position: relative;
      padding: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 0 -2rem;

    .ccard-header {
      display: none;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-header {
      display: flex;
      align-items: center;
      padding: 1.25rem 2rem;
    }
  }
}

.input {
  width: 9rem;
}

.reset-date {
  cursor: pointer;
}

.grid-wrap {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.grid {
  display: grid;
  width: 119%;
  grid-template-columns: 1fr 1fr 1fr 5.5rem 10.5rem 1fr 1fr 1fr 1fr 1fr 5.5rem 1fr 2fr 2fr 1fr 4rem;

  &__cell {
    padding: 0.75rem;
    border-top: 1px solid var(--grid-border);
    position: relative;
    justify-self: stretch;

    .inner {
      padding: 0.75rem;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &._cursor {
        cursor: pointer;
      }
    }

    &:hover {
      overflow: visible;
      //z-index: 2;
      .inner {
        width: unset;
        z-index: 10;
        background: var(--second-card-bg);
      }
    }

    &_header {
      min-height: calc(0.875rem * 1.5); // минимальная высота в 1 строку
      box-sizing: content-box;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background: var(--second-card-bg);
      //border: none;
      position: sticky;
      top: 0;
      z-index: 10;

      &:hover {
        overflow: visible;
        //z-index: 11;
      }
    }
  }
}
</style>
