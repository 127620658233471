var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        title: `${_vm.$t("lk.permission.changeBalance.title")}`,
        centered: "",
        "add-content-classes": "ccontent",
        "close-on-backdrop": false,
        show: _vm.showBalanceModal,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show-balance-modal", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "primary", loading: _vm.loadingRequest },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.changeBalance.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lk.permission.changeBalance.buttonSave")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "dark" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:show-balance-modal", false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lk.permission.changeBalance.buttonCancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CInput", {
        attrs: {
          label: `${_vm.$t(
            "lk.permission.changeBalance.calculatedInputLabel"
          )}`,
          "is-valid": _vm.isValidNewBalance,
          "invalid-feedback": `${_vm.$t(
            "lk.permission.changeBalance.invalidFeedback"
          )}`,
        },
        model: {
          value: _vm.newBalance,
          callback: function ($$v) {
            _vm.newBalance = $$v
          },
          expression: "newBalance",
        },
      }),
      _c("CInput", {
        attrs: {
          value: _vm.balanceChangeValue,
          disabled: "",
          label: `${_vm.$t("lk.permission.changeBalance.inputLabel")}`,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }