var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "permissions d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("h1", { staticClass: "title mb-0" }, [
            _vm._v(_vm._s(_vm.$t("lk.header.permission"))),
          ]),
          _c(
            "CButton",
            {
              staticClass: "cbtn",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  _vm.showAccessModal = true
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lk.permission.giveAccess")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("lk.permission.giveAccess"),
            centered: "",
            "add-content-classes": "ccontent",
            show: _vm.showAccessModal,
          },
          on: {
            "update:show": function ($event) {
              _vm.showAccessModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            color: "primary",
                            loading: _vm.loadingRequest,
                            disabled: !_vm.givePermissionShow,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.givePermission.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.permission.giveAccess")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: { color: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.showAccessModal = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-select-input", {
            attrs: {
              options: _vm.userList,
              clearable: false,
              "get-option-label": (option) => option.email,
              placeholder: _vm.$t("lk.permission.emailPlaceholder"),
            },
            on: { search: _vm.onSearch },
            scopedSlots: _vm._u([
              {
                key: "prepend",
                fn: function () {
                  return [_c("CIcon", { attrs: { name: "mail" } })]
                },
                proxy: true,
              },
              {
                key: "no-options",
                fn: function () {
                  return [
                    _vm._v(" " + _vm._s(_vm.$t("general.emptySearch")) + " "),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.user,
              callback: function ($$v) {
                _vm.user = $$v
              },
              expression: "user",
            },
          }),
          _c("v-date-picker", {
            attrs: {
              mode: "date",
              "is-dark": _vm.$store.getters.darkMode,
              masks: {
                input: "DD.MM.YYYY",
              },
              timezone: "utc",
              locale: _vm.$root.$i18n.locale,
              "min-date": new Date(),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ inputValue, inputEvents }) {
                  return [
                    _c(
                      "CInput",
                      _vm._g(
                        {
                          staticClass: "input",
                          attrs: {
                            value: inputValue,
                            placeholder: _vm.$t(
                              "lk.permission.selectAnExpirationDate"
                            ),
                            readonly: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend-content",
                                fn: function () {
                                  return [
                                    inputValue
                                      ? _c("CIcon", {
                                          staticClass: "text-danger reset-date",
                                          attrs: { name: "cil-x" },
                                          nativeOn: {
                                            click: function ($event) {
                                              _vm.tariffExpired = null
                                            },
                                          },
                                        })
                                      : _c("CIcon", {
                                          attrs: { name: "cil-calendar" },
                                        }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        inputEvents
                      )
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.tariffExpired,
              callback: function ($$v) {
                _vm.tariffExpired = $$v
              },
              expression: "tariffExpired",
            },
          }),
          _c("CInput", {
            attrs: {
              value: _vm.tariffDaysLeft,
              placeholder: _vm.$t("lk.permission.numberOfDaysOfAccess"),
              readonly: "",
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-content",
                fn: function () {
                  return [_c("CIcon", { attrs: { name: "cil-calendar" } })]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CSelect", {
            attrs: {
              value: _vm.tariffId,
              label: `${_vm.$t("lk.permission.subscription")}:`,
              placeholder: _vm.$t("lk.permission.chooseAPlan"),
              options: _vm.tariffList,
            },
            on: {
              "update:value": function ($event) {
                _vm.tariffId = $event
              },
            },
          }),
        ],
        1
      ),
      _vm.showBalanceModal
        ? _c("change-user-balance", {
            attrs: {
              user: _vm.user,
              "show-balance-modal": _vm.showBalanceModal,
            },
            on: {
              "update:showBalanceModal": function ($event) {
                _vm.showBalanceModal = $event
              },
              "update:show-balance-modal": function ($event) {
                _vm.showBalanceModal = $event
              },
              "update-users": function ($event) {
                return _vm.$refs.table.getUsers()
              },
            },
          })
        : _vm._e(),
      _c("permission-table", {
        ref: "table",
        staticClass: "h-100 overflow-hidden",
        on: {
          openAccessModal: function ($event) {
            _vm.showAccessModal = true
            _vm.user = $event ? $event : null
          },
          openBalanceModal: _vm.handleBalanceEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }