<template>
  <div class="permissions d-flex flex-column">
    <div class="header">
      <h1 class="title mb-0">{{$t('lk.header.permission')}}</h1>
      <CButton
        class="cbtn"
        color="primary"
        @click="showAccessModal = true"
      >
        {{ $t('lk.permission.giveAccess') }}
      </CButton>
    </div>

    <CModal
      :title="$t('lk.permission.giveAccess')"
      centered
      add-content-classes="ccontent"
      :show="showAccessModal"
      @update:show="showAccessModal = $event"
    >
      <v-select-input
        v-model="user"
        :options="userList"
        :clearable="false"
        :get-option-label="option => option.email"
        :placeholder="$t('lk.permission.emailPlaceholder')"
        @search="onSearch"
      >
        <template #prepend>
          <CIcon name="mail" />
        </template>
        <template #no-options>
          {{ $t('general.emptySearch') }}
        </template>
      </v-select-input>

      <v-date-picker
        mode="date"
        v-model="tariffExpired"
        :is-dark="$store.getters.darkMode"
        :masks="{
          input: 'DD.MM.YYYY',
        }"
        timezone="utc"
        :locale="$root.$i18n.locale"
        :min-date="new Date()"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <CInput
            :value="inputValue"
            :placeholder="$t('lk.permission.selectAnExpirationDate')"
            class="input"
            readonly
            v-on="inputEvents"
          >
            <template #prepend-content>
              <CIcon
                v-if="inputValue"
                name="cil-x"
                class="text-danger reset-date"
                @click.native="tariffExpired = null"
              />
              <CIcon
                v-else
                name="cil-calendar"
              />
            </template>
          </CInput>
        </template>
      </v-date-picker>

      <CInput
        :value="tariffDaysLeft"
        :placeholder="$t('lk.permission.numberOfDaysOfAccess')"
        readonly
      >
        <template #prepend-content>
          <CIcon
            name="cil-calendar"
          />
        </template>
      </CInput>

      <CSelect
        :value.sync="tariffId"
        :label="`${$t('lk.permission.subscription')}:`"
        :placeholder="$t('lk.permission.chooseAPlan')"
        :options="tariffList"
      />

      <template v-slot:footer>
        <div class="w-100">
          <CLoadingButtonCustom
            class="mr-3"
            color="primary"
            :loading="loadingRequest"
            :disabled="!givePermissionShow"
            @click.native="givePermission"
          >
            {{ $t('lk.permission.giveAccess') }}
          </CLoadingButtonCustom>
          <CButton
            color="dark"
            @click="showAccessModal = false"
          >
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </template>
    </CModal>

    <change-user-balance
      v-if="showBalanceModal"
      :user="user"
      :show-balance-modal.sync="showBalanceModal"
      @update-users="$refs.table.getUsers()"
    />
    <permission-table
      ref="table"
      class="h-100 overflow-hidden"
      @openAccessModal="showAccessModal = true; user = $event ? $event : null"
      @openBalanceModal="handleBalanceEvent"
    />
  </div>
</template>

<script>
import PermissionTable from "@/components/permision/PermissionTable";
import axios from "axios";
import formatTariffsResponse from "@/assets/js/permissions/formatTariffsResponse";
import {DatePicker} from 'v-calendar'
import VSelectInput from "@/components/VSelectInput";
import debounce from 'lodash/debounce'
import moment from "moment";
import ChangeUserBalance from "@/components/lk/ChangeUserBalance";

export default {
  name: "Permission",
  components: {
    ChangeUserBalance,
    PermissionTable,
    'v-date-picker': DatePicker,
    VSelectInput
  },
  data() {
    return {
      userList: [],
      tariffList: [],
      loadingTable: false,
      showAccessModal: false,
      showBalanceModal: false,
      tariffExpired: null,
      tariffId: null,
      user: null,
      loadingRequest: false,
    }
  },
  computed: {
    tariffDaysLeft () {
      if (!this.tariffExpired) return null

      // добавляется один день для визуально правильного отображения (на сервере автоматически до 23:59:59)
      const expired = new moment(this.tariffExpired).add(1, 'day')

      if (expired.isValid()) {
        const now = Date.now()
        const diff = expired.diff(now, 'days')
        return diff
      } else {
        return null
      }
    },
    givePermissionShow () {
      return (
        this.user?.id &&
        this.tariffExpired &&
        this.tariffId
      )
    }
  },
  created() {
    this.getTariffs()
  },
  methods: {
    handleBalanceEvent(user) {
      this.showBalanceModal = true
      this.user = user
    },
    getTariffs () {
      axios.get('/api/v2/tariffs')
        .then(({ data }) => {
          if (data?.data && Array.isArray(data.data)) {
            this.tariffList = formatTariffsResponse(data.data, this.$root.$i18n.locale)
          }
        })
    },
    givePermission () {
      if (this.givePermissionShow) {
        this.loadingRequest = true
        const formData = new URLSearchParams()
        formData.append('tariff_expired', moment(this.tariffExpired).format('YYYY-MM-DD'))
        formData.append('tariff_id', this.tariffId)

        axios.put('api/v1/users/' + this.user.id, formData)
          .then(({status}) => {
            if (status < 300) {
              this.tariffExpired = null
              this.tariffId = null
              this.user = null
              this.$toastr.success(this.$t('lk.permission.accessGrantedSuccessfully'))
              this.showAccessModal = false
              this.$refs.table.getUsers()
            }
          })
          .finally(() => {
            this.loadingRequest = false
          })
      }
    },
    onSearch(search, loading) {
      if(search.length && search.length >= 3) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.get('api/v1/users/autocomplete', {params: {term: search}})
        .then(res => {
          vm.userList = res.data.data
        })
        .finally(() => {
          loading(false);
        })
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cbtn {
    height: 2.75rem;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 1.25rem;

    .title {
      display: none;
    }
    .cbtn {
      width: 100%;
    }
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 1.75rem;

    .title {
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 2.25rem;

    .title {
      font-size: 2.25rem;
    }
  }
}

/deep/ .modal-dialog {
  @include media-breakpoint-down(xs) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  @include media-breakpoint-up(sm) {
    max-width: 20rem;
  }
  @include media-breakpoint-up(xl) {
    max-width: 35.75rem;
  }
}

/deep/ .ccontent {
  .cinput .form-control, .cbtn {
    height: 2.75rem;
  }

  .cbtn {
    width: 100%;
  }

  .modal-header, .modal-footer {
    border: 0;
  }

  @include media-breakpoint-down(xs) {
    .modal-header {
      padding: 1.25rem;
    }
    .modal-body {
      padding: 0 1.25rem;
    }
    .modal-footer {
      padding: 1.75rem 1.25rem 3.75rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .modal-header {
      padding: 1.25rem 1.75rem 2rem;
    }
    .modal-body {
      padding: 0 1.75rem;
    }
    .modal-footer {
      padding: 1.75rem;
      padding-bottom: 3.75rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .modal-header {
      padding: 3.25rem;
      padding-bottom: 2.25rem;

      .modal-title {
        font-size: 1.25rem;
      }
    }
    .modal-body {
      padding: 0 3.25rem;
    }
    .modal-footer {
      padding: 3.25rem;
      padding-top: 2rem;
    }
    .cinput {
      margin-bottom: 1.25rem;
    }
  }
}

.permissions {
  @include media-breakpoint-down(lg) {
    @media (min-height: 550px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media (min-height: 700px) {
      height: 100%;
    }
  }
}
</style>
