<template>
  <CModal
    :title="`${$t('lk.permission.changeBalance.title')}`"
    centered
    add-content-classes="ccontent"
    :close-on-backdrop="false"
    :show="showBalanceModal"
    @update:show="$emit('update:show-balance-modal', $event)"
  >
    <CInput
      v-model="newBalance"
      :label="`${$t('lk.permission.changeBalance.calculatedInputLabel')}`"
      :is-valid="isValidNewBalance"
      :invalid-feedback="`${$t('lk.permission.changeBalance.invalidFeedback')}`"
    />
    <CInput
      :value="balanceChangeValue"
      disabled
      :label="`${$t('lk.permission.changeBalance.inputLabel')}`"
    />
    <template v-slot:footer>
      <div class="w-100">
        <CLoadingButtonCustom
          class="mr-3"
          color="primary"
          :loading="loadingRequest"
          @click.native="changeBalance"
        >
          {{ $t('lk.permission.changeBalance.buttonSave') }}
        </CLoadingButtonCustom>
        <CButton
          color="dark"
          @click="$emit('update:show-balance-modal', false)"
        >
          {{ $t('lk.permission.changeBalance.buttonCancel') }}
        </CButton>
      </div>
    </template>
  </CModal>
</template>
<script>
import axios from "axios";

export default {
  name: 'change-user-balance',
  props: {
    showBalanceModal: {},
    user: {}
  },
  data() {
    return {
      newBalance: 0,
      loadingRequest: false,
    }
  },
  computed: {
    balanceChangeValue() {
      if (this.user) {
        return this.saveParseInt(this.newBalance) + this.saveParseInt(this.user.balance)
      } else {
        return 0
      }
    },
    isValidNewBalance() {
      return this.saveParseInt(this.newBalance) !== 0
    },
  },
  methods: {
    changeBalance() {
      this.loadingRequest = true
      axios.post('api/v2/transactions', {
        newBalance: this.newBalance,
        user_id: this.user.id
      })
        .then(({data}) => {
          if (data?.status) {
            this.$toastr.success(this.$t('lk.permission.changeBalance.successResponse'))
            this.$emit('update:show-balance-modal', false)
            this.$emit('update-users')
          }
        })
        .catch((er) => {
          console.error(er)
          this.$toastr.error(this.$t('lk.permission.changeBalance.errorResponse'))
        })
        .finally(() => {
          this.loadingRequest = false
        })
    },
    saveParseInt(string) {
      const number = Math.floor(string)
      if (isNaN(number)) {
        return 0
      }
      return number
    },
  }
}
</script>
